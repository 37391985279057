<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <!--<div>-->
          <!--  <span>分类：</span>-->
          <!--  <el-select v-model="pageInfo.questionTypeId"-->
          <!--             class="m-2"-->
          <!--             placeholder="选择分类"-->
          <!--             size="large">-->
          <!--    <el-option-->
          <!--        v-for="item in classificationOptions"-->
          <!--        :key="item.value"-->
          <!--        :label="item.label"-->
          <!--        :value="item.value"-->
          <!--    />-->
          <!--  </el-select>-->
          <!--</div>-->
          <div>
            <span>试卷名称：</span>
            <input v-model="pageInfo.name" placeholder="试卷名称" class="searchInput" />
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">试卷列表</div>
          <div class="flex">
            <!-- 录入题目 -->
            <button class="addBtn marginLR10" @click="showEdit(null)"> + 新增</button>
          </div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="id" label="编号" />
          <el-table-column prop="name" label="试卷名称" />
          <el-table-column prop="score" label="合格分数" />
          <el-table-column prop="time" label="创建时间" />
          <el-table-column fixed="right" label="操作" width="200">
            <template #default="scope"><!-- scope.$index 表示 序列号 0、1、2 -->
              <el-button link type="primary" style="color: #3769B9" @click="showEdit(scope.row)">编辑</el-button>
              <el-button link type="primary" style="color: #3769B9" @click="copyExamPaper(scope.row)">复制</el-button>
              <el-button link type="primary" style="color: #3769B9" @click="addQuestion(scope.row)">编辑题目</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <el-dialog v-model="editExamPopup"
               title="编辑试卷"
               class="info-div600"
               destroy-on-close
               style="padding: 24px;text-align: left">
      <div>
        <div style="border-bottom: 1px solid #EBEBEB;border-top: 1px solid #EBEBEB;margin-top: 16px;padding: 36px 0">
          <div style="margin: auto">
            <el-form :model="pageForm" :rules="pageFormRules" ref="formRef">
              <el-form-item label="试卷名称" prop="name" size="large" label-width="120px">
                <el-input v-model="pageForm.name"
                          autocomplete="off"
                          placeholder="请填写试卷名称"
                          class="room-input-tl" />
              </el-form-item>
              <el-form-item label="合格分数" prop="score" size="large" label-width="120px">
                <el-input v-model="pageForm.score"
                          autocomplete="off"
                          placeholder="请填写合格分数"
                          class="room-input-tl"
                          @blur="floatParse(pageForm.score, val => pageForm.score = val)"/>
              </el-form-item>
              <el-form-item label="出题方式" prop="order" size="large" label-width="120px">
                <el-radio-group v-model="pageForm.order" class="ml-4">
                  <el-radio :label="1" size="large">顺序出题</el-radio>
                  <el-radio :label="0" size="large">乱序出题</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="dialog-footer marginTop30px text-right">
          <button class="subBtn" @click="submit">提交</button>
          <button class="qxBtn" @click="editExamPopup = false">取消</button>
        </div>
      </div>
    </el-dialog>
    <!-- 操作窗口 -->
    <el-dialog v-model="dialogFormVisible"
               title="复制试卷"
               class="info-div600"
               destroy-on-close
               style="padding: 24px;text-align: left">
      <el-form :model="pageFormCopy" :rules="pageFormCopyRules" ref="pageFormCopyRef">
        <el-form-item label="试卷名称" prop="paperName" size="large" label-width="120px">
          <el-input v-model="pageFormCopy.paperName"
                    autocomplete="off"
                    placeholder="请填写复制试卷名称"
                    class="room-input-tl" />
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <button class="subBtn" @click="submitCopy">提交</button>
          <button class="qxBtn" @click="dialogFormVisible = false">取消</button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {addPaper, editPaper, getAllQuestionType, getPaperPage, paperCopy} from "@/api/examination";
import {floatParse} from "@/utils/common";
export default {
  name: "examPaperList",
  data(){
    return{
      tableData:[],
      pageInfo:{
        limit:10,
        page:1,
        total:0,
        // -------------------
        name: '',
      },
      // ------------------------------------
      // 复制行内容
      copyRow: null,
      // 复制
      dialogFormVisible: false,
      // 分类列表
      classificationOptions: [],
      // 编辑|新增
      editExamPopup: false,
      // 编辑行信息
      rowInfo: null,
      // 试卷信息
      pageForm: {
        // 试卷名称
        name: '',
        // 0 不按顺序出题 1 按照顺序出题
        order: '',
        // 合格分数
        score: '',
      },
      pageFormRules: {
        name: [
          {required: true, message: '请填写试卷名称', trigger: 'blur'},
        ],
        order: [
          {required: true, message: '请选择出题方式', trigger: 'blur'},
        ],
        score: [
          {required: true, message: '请填写合格分数', trigger: 'blur'},
        ],
      },
      // 复制试卷
      pageFormCopy: {
        paperName: ''
      },
      pageFormCopyRules: {
        paperName: [
          {required: true, message: '请填写复制试卷的名称', trigger: 'blur'},
        ],
      }
    }
  },
  mounted() {
    this.getList()
    this.getOption()
  },
  methods:{
    floatParse,
    /**
     * 获取分类
     */
    getOption(){
      getAllQuestionType().then(res => {
        res.data.forEach(iter => {
          this.classificationOptions.push({
            label: iter.name,
            value: iter.id
          })
        })
      })
    },
    /**
     * 提交试卷
     */
    submit(){
      this.$refs.formRef.validate((valid, error) => {
        if (valid) {
          let handler = addPaper
          if (this.rowInfo !== null) {
            handler = editPaper
            this.pageForm.id = this.rowInfo.id
          }
          // 新增|编辑
          handler(this.pageForm).then(res => {
            if(res.success){
              this.$root.ElMessage({type:'success',message:res.message});
              // 数据重置
              this.pageForm.id = undefined
              this.rowInfo = null
              setTimeout(() => {
                this.getList()
                this.editExamPopup = false
              }, 500)
            } else{
              this.$root.ElMessage.error(res.message);
            }
          })
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    /**
     * 复制试卷
     */
    copyExamPaper(row){
      this.dialogFormVisible = true
      this.copyRow = row
    },
    /**
     * 复制试卷
     */
    submitCopy(){
      this.$refs.pageFormCopyRef.validate((valid, error) => {
        if (valid) {
          this.pageFormCopy.paperId = this.copyRow.id
          paperCopy(this.pageFormCopy).then(res => {
            if(res.success){
              this.$root.ElMessage({type:'success',message:res.message})
              this.dialogFormVisible = false
              setTimeout(() => {
                this.getList()
              }, 500)
            }else{
              this.$root.ElMessage.error(res.message)
            }
          })
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    /**
     * 重置个别字段
     */
    reset(){
      const resetField = {
        name: '',
      }
      this.pageInfo = { ...this.pageInfo, ...resetField }
    },
    search(){
      this.pageInfo.limit=10;
      this.pageInfo.page=1;
      this.getList();
    },
    getList(){
      getPaperPage(this.pageInfo).then(res=>{
        this.pageInfo.total=parseInt(res.data.total);
        this.tableData=res.data.records;
      })
    },
    /**
     * 新增|添加试卷
     */
    showEdit(row){
      this.rowInfo = row
      if (this.rowInfo !== null) {
        // 回显编辑
        const { name, order, score } = row
        this.pageForm = { ...this.pageForm, name, order, score }
      } else {
        this.resetPageForm()
      }
      // 显示编辑
      this.editExamPopup = true
    },
    resetPageForm(){
      for (let key in this.pageForm) {
        this.pageForm[key] = ''
      }
    },
    /**
     * 编辑题目
     */
    addQuestion(row){
      this.$root.useRouter.push({ path:`/examPaper/add/${row.id}` });
    },
    handleSizeChange(e){//修改每页显示条数
      this.pageInfo.limit=e;
      this.pageInfo.page=1;
      this.getList();
    },
    handleCurrentChange(e){
      this.pageInfo.page=e;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
.data-title{font-size: 15px;color: #000000}
.data-content{
  font-size: 13px;display: flex;justify-content: space-between;margin-top: 15px;
}
</style>